<script>
import LazyYoutubeVideo from "vue-lazy-youtube-video";

export default {
  name: "Main",
  components: {
    LazyYoutubeVideo,
  },
};
</script>

<template>
  <div class="main-wrapper">
    <img alt="nevrida band" class="band-image" :src="'/images/band.png'" />
    <div class="social-icons">
      <a href="https://vk.com/nevrida" target="_blank">
        <img alt="vk" class="icon" :src="'/images/social/vk.svg'" />
      </a>
      <a href="https://www.instagram.com/nevridaland/" target="_blank">
        <img alt="inst" class="icon" :src="'/images/social/inst.svg'"
      /></a>
      <a href="https://www.facebook.com/nevrida" target="_blank">
        <img alt="fb" class="icon" :src="'/images/social/fb.svg'"
      /></a>
      <a href="https://www.youtube.com/nevridaland" target="_blank">
        <img alt="youtube" class="icon" :src="'/images/social/youtube.svg'" />
      </a>
    </div>
    <img alt="okno" class="okno-image" :src="'/images/okno.png'" />
    <div class="album-icons">
      <a class="album-link" href="https://ps.onerpm.com/2740100774">
        <img
          alt="kabluchki"
          class="album-icon"
          :src="'/images/albums/kabluchki.jpeg'"
        />
        <img alt="play" class="play-icon" :src="'/images/play-button.png'" />
      </a>
      <a class="album-link" href="https://ps.onerpm.com/3227207815">
        <img alt="ogni" class="album-icon" :src="'/images/albums/ogni.jpeg'" />
        <img alt="play" class="play-icon" :src="'/images/play-button.png'" />
      </a>
      <a class="album-link" href="https://onerpm.link/1488382707">
        <img alt="omut" class="album-icon" :src="'/images/albums/omut.jpeg'" />
        <img alt="play" class="play-icon" :src="'/images/play-button.png'" />
      </a>
      <a class="album-link" href="https://onerpm.link/800192216601"
        ><img
          alt="nikto"
          class="album-icon"
          :src="'/images/albums/nikto.jpeg'"
        />
        <img alt="play" class="play-icon" :src="'/images/play-button.png'" />
      </a>
      <a class="album-link" href="https://onerpm.link/3513763204"
        ><img
          alt="omut-radio"
          class="album-icon"
          :src="'/images/albums/omut-radio.jpeg'"
        />
        <img alt="play" class="play-icon" :src="'/images/play-button.png'" />
      </a>
    </div>
    <div class="video">
      <LazyYoutubeVideo
        src="https://www.youtube.com/embed/EMILcfZ-vsk"
        aspect-ratio="21:9"
        :preview-image-size="'maxresdefault'"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .band-image {
    width: 70vw;
    height: auto;
    @media screen and (min-width: 620px) {
      width: 40vw;
    }
  }
  .social-icons {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .icon {
      width: 50px;
      height: 50px;
      margin: 30px;

      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .okno-image {
    width: 100%;
    max-width: 200px;
    height: auto;
  }
  .album-icons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .album-link {
      position: relative;
      margin: 10px 5px;
      transition: box-shadow 0.3s linear, opacity 0.3s linear;

      &:hover {
        -webkit-box-shadow: 0 0 9px 5px rgba(255, 255, 255, 0.5);
        box-shadow: 0 0 9px 5px rgba(255, 255, 255, 0.5);
        .play-icon {
          visibility: visible;
          opacity: 1;
        }
      }

      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
    .album-icon {
      width: 14vw;
      height: auto;
      @media screen and (min-width: 620px) {
        width: 12vw;
      }
    }
    .play-icon {
      @media screen and (min-width: 620px) {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.3s linear, opacity 0.3s linear;
      }

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 50%;
      width: 50%;
    }
  }
  .video {
    margin: 30px 0;
    width: 50%;
    ::v-deep .video-wrapper {
      //width: 50%;
      //img {
      //  width: 100%;
      //}
      //
      //.y-video__inner {
      //  padding: 0 !important;
      //}
    }
  }
}
</style>
