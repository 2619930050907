<script>
export default {
  name: "Footer",
};
</script>

<template>
  <div class="footer-wrapper">
    <p>по вопросам организации концертов:</p>
    <p>
      <a href="tel:+375291669234">+375(29)1669234</a> /
      <a href="mailto:nevridaland@gmal.com">nevridaland@gmal.com</a>
    </p>
    <p>© Nevrida</p>
  </div>
</template>

<style lang="scss" scoped>
.footer-wrapper {
  p {
    margin: 0;
  }
}
</style>
