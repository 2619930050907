<script>
import "./assets/reset.css";
import "./assets/app.css";
import Footer from "./components/Footer.vue";
import Main from "./components/Main.vue";

export default {
  name: "Index",
  components: {
    Footer,
    Main,
  },
};
</script>

<template>
  <div id="main-page" :style="{ backgroundImage: 'url(/images/bg.jpeg)' }">
    <Main />
    <Footer />
  </div>
</template>

<style lang="scss" scoped>
#main-page {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #131313;
  padding: 0 10vw 30px;
  //
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  color: white;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
</style>
